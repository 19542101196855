.eclipse {
    position: fixed;
    background-color: rgba(246, 248, 246, 0.904);
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    z-index: 1000000;
    transform: translate(-50%,-50%);
}
.spiner {
    position: fixed;
    background-color: #00000000;
    width: 120px;
    height: 120px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.spiner i {
    font-size: 100px;
    color: #20a8d8;
}